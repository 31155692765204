import api from "./api";

export default {
  getDescription(searchParams) {
    const urlSearchParams = new URLSearchParams(searchParams);
    return api
      .get("/money-service/description/?" + urlSearchParams.toString())
      .then((response) => response.data);
  },
};
